import React, { useRef, useEffect } from "react"
import soundUrl from "../sounds/Comon_masculino_02.mp3"
import { useTranslation } from "react-i18next"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import gsap from "gsap"

import { Howl } from "howler"

const AudioButton = () => {
  const sound = new Howl({
    src: [soundUrl],
    html5: true,
  })

  const handleButton = e => {
    e.preventDefault()
    sound.play()
  }
  return (
    <button
      className="text-white h-10 md:h-16 px-4 md:px-8 rounded-full inline-flex items-center mx-4 bg-blue transition-all transform hover:bg-negative"
      onMouseDown={handleButton}
      onTouchStart={handleButton}
    >
      <span className="pr-2 text-18 font-base uppercase">(Kó-món)</span>
      <svg
        viewBox="0 0 36 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-auto"
      >
        <path
          className="line1"
          d="M2 8L2 16"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
          style={{ transformOrigin: "center" }}
        />
        <path
          d="M9.99999 4L10 20"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M18 7.83337L18 16"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M26 2L26 22"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M34 6L34 18"
          stroke="white"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </svg>
    </button>
  )
}

const SectionIntroWithButton = ({
  translation,
  animated,
  animationOptions,
}) => {
  const [t] = useTranslation()
  const breakpoints = useBreakpoint()
  const refs = useRef([])

  const lines = t(`${translation}.${breakpoints.lg ? "desktop" : "mobile"}`, {
    returnObjects: true,
  })

  useEffect(() => {
    refs.current = refs.current.slice(0, lines.length)
  }, [lines])

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(refs.current, {
      scrollTrigger: {
        trigger: animationOptions?.trigger,
        start: animationOptions.top || "top center",
      },
      opacity: 0,
      y: 100,
      stagger: 0.15,
      duration: 0.5,
      delay: 0.4,
      ease: "power3.easeOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div className="mt-12">
      {lines.map((line, index) => {
        const spans = line.split("#sound-button#")

        return (
          <div
            key={index}
            className="overflow-hidden flex text-64 font-base uppercase text-dark font-light"
          >
            <div
              className="flex items-center"
              ref={element => {
                refs.current.push(element)
              }}
            >
              {spans.map((l, lineIndex) => {
                return (
                  <React.Fragment key={lineIndex}>
                    <div
                      style={{
                        boxSizing: "border-box",
                        display: "inline",
                      }}
                      className="line"
                      dangerouslySetInnerHTML={{
                        __html: l,
                      }}
                    />
                    {lineIndex < spans.length - 1 ? <AudioButton /> : null}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SectionIntroWithButton
