import React from "react"

import SectionDescription from "@components/SectionDescription"
import SectionTitleTransformation from "@components/SectionTitleTransformation"
import SectionIntro from "@components/SectionIntro"
import ProjectItem from "@components/ProjectItem"
import SectionLine from "@components/SectionLine"

import { useModal } from "../../providers/modal"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Transformation = ({ name, intro, description, projects }) => {
  const { showModal } = useModal()
  const breakpoints = useBreakpoint()

  const evenProjects = [],
    oddProjects = []

  projects.forEach((project, index) => {
    if (index % 2 === 0) {
      evenProjects.push(project)
    } else {
      oddProjects.push(project)
    }
  })

  const showProject = project => {
    showModal({
      id: "project",
      data: project,
    })
  }

  return (
    <div id={`${name}-transformation`} className="pt-40 md:pt-96">
      <SectionTitleTransformation
        text={name}
        animated={breakpoints.lg}
        animationOptions={{ trigger: `#${name}-transformation` }}
      />

      <SectionIntro
        translation={intro}
        color="white"
        className="mt-14 md:mt-12"
        animated={breakpoints.lg}
        animationOptions={{ trigger: `#${name}-transformation` }}
      />

      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-12 md:mt-24">
        <div className="col-span-full md:col-span-5 order-last md:order-none flex justify-center">
          {evenProjects.map(project => (
            <ProjectItem
              key={project.name}
              project={project}
              onClick={() => showProject(project)}
            />
          ))}
        </div>
        <div className="col-span-full md:col-span-5">
          <SectionLine
            background="white"
            animated={breakpoints.lg}
            animationOptions={{ trigger: `#${name}-transformation` }}
          />
          <SectionDescription
            className="mt-6 mb-16 md:mt-12 md:mb-40"
            color="white"
            translation={description}
            animated={breakpoints.lg}
            animationOptions={{ trigger: `#${name}-transformation` }}
          />

          <div className="flex justify-center">
            {oddProjects.map(project => (
              <ProjectItem
                key={project.name}
                project={project}
                onClick={() => showProject(project)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transformation
