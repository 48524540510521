import React, { useRef, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap/all"

const SectionTitle = ({
  text,
  translation,
  color,
  className,
  animated,
  animationOptions,
}) => {
  const [t] = useTranslation()

  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: animationOptions.trigger,
        start: animationOptions.top || "top center",
      },
      y: "100px",
      opacity: 0,
      duration: 1,
      ease: "power3.easeInOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div ref={ref} className="flex items-center pt-10 md:pt-72">
      <div className="w-4 h-4 bg-blue rounded-full mr-1" />
      <h3
        className={`uppercase text-18 font-base font-normal text-${
          color ? color : "dark"
        } ${className ? className : ""}`}
      >
        {translation ? t(translation) : text}
      </h3>
    </div>
  )
}

export default SectionTitle
