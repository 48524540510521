import React from "react"

import Section from "@components/Section"
import SectionDescription from "@components/SectionDescription"
import SectionTitle from "@components/SectionTitle"
import SectionLine from "@components/SectionLine"
import SectionImage from "@components/SectionImage"
import SectionIntroWithButton from "@components/SectionIntroWithButton"

import image from "../../images/20AnosExperiencia.jpg"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Intro = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section
      id="intro"
      background="positive"
      className="pb-10 md:pb-48 intro"
      container
    >
      <SectionTitle
        translation="intro.section.title"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#intro" }}
      />

      <SectionIntroWithButton
        translation="intro.section.intro"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#intro", top: "top center" }}
      />

      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 md:mt-24 mt-14">
        <div className="col-span-full md:col-span-5 order-last md:order-none">
          <SectionImage
            type="pill"
            src={image}
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#intro", top: "top center" }}
          />
        </div>
        <div className="col-span-full md:col-span-5 col-start-2 md:col-start-auto">
          <SectionLine
            width="full"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#intro", top: "top center" }}
          />
          <SectionDescription
            className="w-full mt-6 md:mt-12"
            translation="intro.section.description"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#intro", top: "top center" }}
          />
        </div>
      </div>
    </Section>
  )
}

export default Intro
