import React, { useEffect, useRef } from "react"
import gsap from "gsap"

import Section from "@components/Section"
import AnimatedLogo from "@components/AnimatedLogo"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const DEFAULT_COLORS = {
  circleColor: "blue",
  baseColor: "positive",
}

const Overlay = () => {
  const overlayRef = useRef(null)
  const logoRef = useRef(null)
  const sectionRef = useRef(null)

  const breakpoints = useBreakpoint()

  useEffect(() => {
    const overlayAnimation = gsap.to(overlayRef.current, {
      autoAlpha: 0,
      duration: 1,
      delay: 3,
      onComplete: () => {
        document.body.className = ""
      },
    })

    const logoAnimation = gsap.to(logoRef.current, {
      color: "#202020",
      duration: 1,
      delay: 3,
    })

    const sectionAnimation = gsap.to(sectionRef.current, {
      top: breakpoints.md ? "4rem" : "2.75rem",
      delay: 6,
      duration: 1,
      ease: "power3.easeInOut",
    })

    return () => {
      overlayAnimation.kill()
      logoAnimation.kill()
      sectionAnimation.kill()
    }
  }, [breakpoints.md])

  return (
    <>
      <div
        id="overlay"
        ref={overlayRef}
        className="fixed w-screen h-screen bg-negative top-0 z-10"
      ></div>
      <Section
        ref={sectionRef}
        id="logo"
        className={`absolute top-22s md:top-28s z-10`}
        container
      >
        <AnimatedLogo
          ref={logoRef}
          width="24"
          circleColor={DEFAULT_COLORS.circleColor}
          baseColor={DEFAULT_COLORS.baseColor}
          className="md:w-40 mt-3"
        />
      </Section>
    </>
  )
}

export default Overlay
