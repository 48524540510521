import React, { useState } from "react"
import Tilt from "react-parallax-tilt"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import SectionImage from "./SectionImage"

const Client = ({ name, hover }) => (
  <div className="flex items-center">
    <div
      className={`w-4 h-4 transition-all ${
        hover ? "bg-white" : "bg-blue"
      } rounded-full mr-1`}
    />

    <h3 className={`uppercase font-sm font-base font-bold text-white text-14`}>
      <span className="font-light">Client</span>
      {` — ${name}`}
    </h3>
  </div>
)

const Image = ({ src, onMouseEnter, onMouseLeave }) => (
  <div className="w-full h-full rounded-full overflow-hidden relative safari-image-mask">
    <div
      className="w-full h-full rounded-full bg-center bg-no-repeat bg-cover bg-top transition-all transform hover:scale-110"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ backgroundImage: `url(${src})` }}
    />
  </div>
)

const ProjectItem = ({ project, onClick }) => {
  const [hovering, setHovering] = useState(false)

  const handleMouseEnter = () => {
    setHovering(true)
  }

  const handleMouseLeave = () => setHovering(false)
  const breakpoints = useBreakpoint()

  return (
    <Tilt
      tiltMaxAngleX={breakpoints.md || breakpoints.lg ? 40 : 0}
      tiltMaxAngleY={breakpoints.md || breakpoints.lg ? 40 : 0}
      perspective={breakpoints.md || breakpoints.lg ? 1000 : 0}
      transitionSpeed={breakpoints.md || breakpoints.lg ? 1500 : 0}
    >
      <div
        className="tap-highlight-none pill-image rounded-full relative cursor-pointer mx-auto md:mx-0 mt-10 md:mt-0 shadow-3xl"
        onClick={onClick}
      >
        {project.image ? (
          <Image
            src={project.image.thumbnail}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        ) : null}
        <div
          className="w-full relative"
          style={{ bottom: "8.5rem", left: "-2rem" }}
        >
          <Client name={project.client} hover={hovering} />
          <h2
            className="px-5 text-40 text-white uppercase mt-2 md:mt-6"
            dangerouslySetInnerHTML={{ __html: project.name }}
          />
        </div>
      </div>
    </Tilt>
  )
}

export default ProjectItem
