import React, { useEffect, useState, useRef, forwardRef } from "react"
import gsap from "gsap"

import Layout from "@components/Section"
import SectionIntro from "@components/SectionIntro"
import SectionDescription from "@components/SectionDescription"
import SectionTitle from "@components/SectionTitle"
import SectionLine from "@components/SectionLine"
import SectionImage from "@components/SectionImage"

import Person from "../../images/Co-creation.jpg"
import skills from "../../data/skills"
import { useBreakpoint, withBreakpoints } from "gatsby-plugin-breakpoints"

const getMinMaxRandom = (min, max) =>
  Math.floor(Math.random() * (max - min) + min)

const SkillButton = ({ skill, selected, onClick }) => {
  const [hovering, setHovering] = useState(true)
  const buttonRef = useRef(null)

  const handleMouseEnter = () => {
    if (selected) return

    setHovering(true)
  }

  const handleMouseLeave = () => {
    if (selected) return

    setHovering(false)
  }

  /*useEffect(() => {
    buttonRef.current.style.width = `${
      selected
        ? (skill.person.length + skill.position.length) * 12
        : skill.name.length * 15
    }px`

    if (selected) {
      setHovering(false)
    }
  }, [selected])*/

  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
      className={`shadow-pill cursor-default whitespace-nowrap overflow-hidden transition-all duration-200 ease-in-out px-3 py-2 md:px-6 md:py-4 text-14 md:text-16 flex items-center justify-center font-base uppercase rounded-full border-2 md:border-4 ${
        selected
          ? "text-white bg-blue border-blue"
          : "text-white bg-transparent"
      } ${hovering ? "bg-white border-white" : "border-grey-600"}`}
    >
      {selected ? (
        <>
          <span className="font-bold">{skill?.person}</span>
          <span className="mx-1">-</span>
          <span>{skill?.position}</span>
        </>
      ) : (
        <span
          className={`${
            hovering && !selected
              ? "text-black"
              : "text-white mix-blend-exclusion"
          }`}
        >
          {skill.name}
        </span>
      )}
    </button>
  )
}

const AnimatedImage = ({ src }) => {
  const [image, setImage] = useState(null)
  const [isAnimating, setAnimating] = useState(false)
  const imgRef = useRef(null)

  useEffect(() => {
    gsap.to(imgRef.current, {
      opacity: 1,
      delay: 0.15,
      duration: 0.26,
      ease: "power3.easeInOut",
      onComplete: () => {
        setAnimating(false)
      },
    })
  }, [image])

  useEffect(() => {
    if (isAnimating) {
      gsap.killTweensOf(imgRef.current)
    }

    gsap.to(imgRef.current, {
      opacity: 0,
      duration: 0.26,
      ease: "power3.easeInOut",
      onStart: () => {
        setAnimating(true)
      },
      onComplete: () => {
        setImage(src)
      },
    })
  }, [src])

  return <SectionImage ref={imgRef} type="pill" src={image} />
}

const CoCreation = () => {
  const [filteredSkills, setFilteredSkills] = useState(skills)
  const sectionRef = useRef()
  const skillsRef = useRef([])
  const breakpoints = useBreakpoint()

  useEffect(() => {
    if (breakpoints.lg) return setFilteredSkills(skills)

    const MOBILE_SKILLS_COUNT = 8

    const start = getMinMaxRandom(0, skills.length - MOBILE_SKILLS_COUNT - 1)

    setFilteredSkills(skills.slice(start, start + MOBILE_SKILLS_COUNT))
  }, [breakpoints.lg])

  useEffect(() => {
    if (!breakpoints.lg) return

    skillsRef.current = skillsRef.current.slice(0, skills.length)

    /*const animation = gsap.from(skillsRef.current, {
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top",
      },
      y: 100,
      opacity: 0,
      stagger: 0.3,
    })

    return () => {
      animation.kill()
    }*/
  }, [breakpoints.lg])

  return (
    <Layout
      id="co-creation"
      ref={sectionRef}
      background="positive"
      className="pb-24"
      container
    >
      <SectionTitle
        translation="co_creation.section.title"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#co-creation" }}
      />

      <SectionIntro
        className="mt-12"
        size="64"
        translation="co_creation.section.intro"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#co-creation" }}
      />

      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 md:flex-row mt-14 md:mt-36">
        <div className="col-span-full md:col-span-5 order-last md:order-none">
          <SectionImage
            type="pill"
            src={Person}
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#co-creation", delay: 2 }}
          />
        </div>
        <div className="col-span-full md:col-span-5 col-start-2 md:col-start-auto">
          <SectionLine
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#co-creation", delay: 1.2 }}
          />
          <SectionDescription
            className="mt-6 md:mt-12"
            translation="co_creation.section.description"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#co-creation", delay: 1.6 }}
          />
        </div>
      </div>
      <div className="flex flex-wrap content-center -mt-12 md:-mt-20 z-10 relative">
        {filteredSkills.map(skill => (
          <div
            ref={element => skillsRef.current.push(element)}
            key={skill.name}
            className="mx-1 my-1 md:mx-2 md:my-3"
          >
            <SkillButton skill={skill} onClick={() => {}} selected={false} />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default CoCreation
