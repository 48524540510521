import React, { useEffect, useState } from "react"

//import "../config/i18n"

import Hero from "../sections/hero"
import Intro from "../sections/intro"
import Experience from "../sections/experience"
import Marquee from "../sections/marquee"
import Portfolio from "../sections/portfolio"
import CoCreation from "../sections/co-creation"
import HumanFirst from "../sections/human-first"
import Contacts from "../sections/contacts"
import Footer from "../sections/footer"
import Header from "../sections/header"

import ModalProvider from "../providers/modal"
import SEO from "../components/seo"

import ScrollTrigger from "gsap/ScrollTrigger"
import Overlay from "../sections/overlay"
import GridVisualizer from "@components/GridVisualizer"

const IndexPage = () => {
  const [showGrid, setShowGrid] = useState()

  useEffect(() => {
    document.body.className = "overflow-hidden"

    let currentHeight = document.body.offsetHeight
    ScrollTrigger.refresh()

    const timeout = setTimeout(() => {
      if (document.body.offsetHeight !== currentHeight) {
        ScrollTrigger.refresh()
      }
    }, 1000)

    const url = new URLSearchParams(window.location.search)

    if (url.get("debug") && url.get("show_grid")) {
      setShowGrid(true)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <main className="bg-negative">
      <ModalProvider>
        {showGrid && <GridVisualizer />}
        <SEO />
        <Overlay />
        <Hero />
        <Intro />
        <CoCreation />
        <Marquee />
        <HumanFirst />
        <Experience />
        <Portfolio />
        <Contacts />
        <Footer />
        <Header />
      </ModalProvider>
    </main>
  )
}

export default IndexPage
