import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Layout from "@components/Section"
import SectionIntro from "@components/SectionIntro"
import SectionTitle from "@components/SectionTitle"
import SectionLine from "@components/SectionLine"
import SectionDescription from "@components/SectionDescription"

import Infographic from "../../assets/infographic.svg"
/*
import lottie from "lottie-web"
import reposAnim from "../../assets/lottie/repos.json"*/
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Experience = () => {
  //const lottieRef = useRef(null)
  const sectionRef = useRef(null)

  const breakpoints = useBreakpoint()

  useEffect(() => {
    /*const anim = lottie.loadAnimation({
      container: lottieRef.current,
      animationData: reposAnim,
      renderer: "canvas", // "canvas", "html"
      loop: false, // boolean
      autoplay: false, // boolean
      //path: "../../assets/lottie/repos.json",
    })

    const lottieTrigger = ScrollTrigger.create({
      trigger: lottieRef.current,
      start: "top center",
      delay: 1,
      onEnter: () => {
        anim.play()
      },
      onLeave: () => {
        anim.stop()
      },
      onEnterBack: () => {
        anim.play()
      },
      onLeaveBack: () => {
        anim.stop()
      },
      //toggleActions: "play pause resume reset",
    })*/

    if (!breakpoints.lg) return

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "+=500px",
        end: "=+850px",
        scrub: 1,
      },
      reversed: true,
    })

    timeline.to(sectionRef.current, {
      backgroundColor: "rgba(40, 40, 255, 0.5)",
    })

    return () => {
      //lottieTrigger.kill()
      timeline.kill()
    }
  }, [breakpoints.lg])

  return (
    <Layout
      ref={sectionRef}
      id="experience"
      background="negative"
      gradient={{ from: "negative", to: "blue-darker" }}
      className="pb-10 pt-10 md:pb-48 md:pt-24 versatility lg:bg-none"
      container
      styles={{ marginBottom: "0px" }}
    >
      <SectionTitle
        translation="versatility.section.title"
        color="white"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#experience" }}
      />
      <SectionIntro
        className="mt-12"
        color="white"
        translation="versatility.section.intro"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#experience" }}
      />
      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-12">
        <div className="col-span-full md:col-span-5 col-start-2 md:col-start-6">
          <SectionLine
            background="white"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#experience" }}
          />
          <SectionDescription
            className="w-full mt-6 md:mt-12"
            translation="versatility.section.description"
            color="white"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#experience" }}
          />
        </div>
      </div>
      <div className="w-full mt-14 mb-14 md:mt-28 md:mb-28">
        <img
          src={Infographic}
          alt="InfoGraphic"
          width="1308"
          height="1308"
          className="mx-auto"
        />
      </div>

      {/*<div ref={lottieRef} className="mt-14 mb-14" />*/}
    </Layout>
  )
}

export default Experience
