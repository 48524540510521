export default [
  {
    name: "Chief Experience Officer",
  },
  {
    name: "Account Director & Executive",
  },
  {
    name: "Managing Directors",
  },
  {
    name: "Project Managers",
  },
  {
    name: "Strategists",
  },
  {
    name: "Digital Media & Performance Specialists",
  },
  {
    name: "Data Analysts",
  },
  {
    name: "Full-Stack Developers",
  },
  {
    name: "Developers",
  },
  {
    name: "Interaction Designers",
  },
  {
    name: "Visual Designers",
  },
  {
    name: "Video Production / Motion Graphics",
  },
  {
    name: "UX Designers",
  },
  {
    name: "UI Designers",
  },
  {
    name: "Art Directors",
  },
  {
    name: "Copywriters",
  },
  {
    name: "Social Media Managers",
  },
  {
    name: "Designers",
  },
]
