import React from "react"
import { times } from "lodash"

const GridVisualizer = () => (
  <div
    className="container-fluid fixed h-full text-black pointer-events-none"
    style={{ zIndex: 1000 }}
  >
    <div className="hidden md:grid grid-cols-12 gap-8 h-full">
      {times(12).map(n => (
        <div
          key={n}
          className="col-span-1 h-full flex justify-center items-center text-black bg-opacity-25 bg-red"
        >
          {n + 1}
        </div>
      ))}
    </div>
    <div className="grid grid-cols-4 gap-4 md:hidden h-full">
      {times(4).map(n => (
        <div
          key={n}
          className="col-span-1 h-full flex justify-center items-center text-black bg-opacity-25 bg-red"
        >
          {n + 1}
        </div>
      ))}
    </div>
  </div>
)

export default GridVisualizer
