import React, { useRef, useEffect } from "react"
import { gsap } from "gsap/all"

const SectionTitleTransformation = ({
  text,
  color,
  animated,
  animationOptions,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: animationOptions.trigger,
        start: animationOptions.top || "top center",
      },
      y: "100px",
      opacity: 0,
      duration: 1,
      ease: "power3.easeOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div ref={ref} className="flex items-center">
      <div
        className={`border border-${
          color ? color : "white"
        } border-opacity-12 rounded-full flex items-center px-3 py-2 mr-2 md:mr-4`}
      >
        <div className="w-4 h-4 bg-blue rounded-full mr-1" />
        <h3
          className={`uppercase text-18 font-base font-normal text-${
            color ? color : "white"
          }`}
        >
          {text}
        </h3>
      </div>
      <h3
        className={`uppercase opacity-50 text-18 font-base font-normal text-${
          color ? color : "white"
        }`}
      >
        Transformation
      </h3>
    </div>
  )
}

export default SectionTitleTransformation
