import React, { useEffect, useRef } from "react"
import gsap from "gsap"

import Section from "@components/Section"
import LanguageSwitch from "@components/LanguageSwitch"
import ContactButton from "@components/ContactButton"

const Header = () => {
  const contactRef = useRef(null)

  useEffect(() => {
    const contactButtonAnimation = gsap.to("#contact", {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 4,
    })

    const languageSwitchAnimation = gsap.to("#languageSwitch", {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 4,
    })

    return () => {
      contactButtonAnimation.kill()
      languageSwitchAnimation.kill()
    }
  }, [])

  return (
    <header>
      <Section
        ref={contactRef}
        className={`fixed top-11 md:top-16 z-10`}
        container
      >
        <div className="w-full flex flex-none items-center justify-end z-10">
          <div id="contact" className="opacity-0 transform translate-y-40">
            <ContactButton />
          </div>
          <div
            id="languageSwitch"
            className="opacity-0 transform translate-y-40 ml-2 md:ml-4"
          >
            <LanguageSwitch />
          </div>
        </div>
      </Section>
    </header>
  )
}

export default Header
