import React, { forwardRef } from "react"

import Section from "@components/Section"
import SectionIntro from "@components/SectionIntro"
import SectionDescription from "@components/SectionDescription"
import ScrollDown from "@components/ScrollDown"
import FluidBackground from "@components/FluidBackground"

const Intro = forwardRef((props, ref) => {
  return (
    <>
      <FluidBackground maxOrbs={5} />
      <Section
        id="hero"
        ref={ref}
        screen
        container
        background="positive"
        className="flex flex-col justify-center"
      >
        <div className="md:mt-24">
          <SectionIntro
            size="80"
            translation="hero.section.title"
            animated
            animationOptions={{ delay: 4.5 }}
            heading="h1"
          />
        </div>
        <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-10 md:mt-12">
          <div className="col-span-3 md:col-span-4 col-start-2 md:col-start-5">
            <SectionDescription
              translation="hero.section.description"
              animated
              animationOptions={{ delay: 4.5 }}
            />
          </div>
        </div>
        <div className="absolute w-full flex justify-center bottom-6 left-0">
          <ScrollDown />
        </div>
      </Section>
    </>
  )
})

Intro.displayName = "Intro"

export default Intro
