import React from "react"
import { useTranslation } from "react-i18next"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MultilineText from "./MultilineText"
import Heading from "./heading"

const renderTranslation = (translation, animated, animationOptions) => {
  const [t] = useTranslation()
  const breakpoints = useBreakpoint()

  return (
    <MultilineText
      lines={t(`${translation}.${breakpoints.lg ? "desktop" : "mobile"}`, {
        returnObjects: true,
      })}
      animated={animated}
      animationOptions={{ delay: 0.4, ...animationOptions }}
    />
  )
}

const renderText = text => {
  const lines = text.split("\\n")
  return lines.length > 1
    ? lines.map((line, index) => (
        <>
          {line}
          {index + 1 < lines.length ? <br /> : null}
        </>
      ))
    : text
}
/*
const CustomTag = `h${this.props.priority}`;

const Heading = ({ children, type, ...props }) => {
  switch (type) {
    case "h1":
      return <h1 {...props}>{children}</h1>
    case "h2":
      return <h2 {...props}>{children}</h2>
    case "h3":
      return <h3 {...props}>{children}</h3>
    default:
      return <h1 {...props}>{children}</h1>
  }
}*/

const SectionIntro = ({
  text,
  translation,
  className,
  size,
  color,
  animated,
  animationOptions,
  heading,
}) => {
  return (
    <Heading
      type={heading}
      className={`text-${size ? size : "64"} font-base uppercase text-${
        color ? color : "dark"
      } font-light ${className ? className : ""}`}
    >
      {translation
        ? renderTranslation(translation, animated, animationOptions)
        : renderText(text)}
    </Heading>
  )
}

SectionIntro.defaultProps = {
  heading: "h2",
}

export default SectionIntro
