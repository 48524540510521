import React from "react"

import Section from "@components/Section"
import SectionIntro from "@components/SectionIntro"
import SectionDescription from "@components/SectionDescription"
import SectionTitle from "@components/SectionTitle"
import SectionLine from "@components/SectionLine"
import SectionImage from "@components/SectionImage"

import People1 from "../../images/HumanFirst_0.jpg"
import People2 from "../../images/HumanFirst-Circles_1.jpg"
import People3 from "../../images/HumanFirst-Circles_2.jpg"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const HumanFirst = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section
      id="human-first"
      background="positive"
      className="pb-10 md:pb-24"
      container
    >
      <SectionTitle
        translation="marquee.section.title"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#human-first" }}
      />

      <SectionIntro
        className="mt-12"
        translation="human_first.section.intro"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#human-first" }}
      />

      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 md:mt-24 mt-14">
        <div className="col-span-full md:col-span-5 order-last md:order-none">
          <SectionImage
            type="pill"
            src={People1}
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#human-first" }}
          />
          <SectionImage
            type="circle"
            src={People3}
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#human-first", staggerDelay: 0.8 }}
          />
        </div>
        <div className="col-span-full md:col-span-5 col-start-2 md:col-start-auto flex flex-col justify-between">
          <div>
            <SectionLine
              animated={breakpoints.lg}
              animationOptions={{ trigger: "#human-first" }}
            />
            <SectionDescription
              translation="human_first.section.description"
              className="mt-6 md:mt-12"
              animated={breakpoints.lg}
              animationOptions={{ trigger: "#human-first" }}
            />
          </div>
          <SectionImage
            type="circle"
            className="hidden md:block mb-48"
            src={People2}
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#human-first", staggerDelay: 0.4 }}
          />
        </div>
      </div>
    </Section>
  )
}

export default HumanFirst
