import innerDimensions from "../utils/innerDimensions"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import gsap from "gsap"

const AnimatedLogo = forwardRef(
  ({ width, className, baseColor, circleColor }, ref) => {
    const pathRefs = useRef([])
    const [runAnimation, setRunAnimation] = useState(false)

    useEffect(() => {
      const containerDimensions = innerDimensions(ref.current.parentElement)
      const logoDimensions = innerDimensions(ref.current)

      pathRefs.current.forEach(path => {
        path.style.transform = `translateX(${
          containerDimensions.width - logoDimensions.width
        }px)`
      })

      const opacityAnimation = gsap.to(ref.current, {
        opacity: 1,
        ease: "power2.easeOut",
        duration: 1.5,
        delay: 2,
        onStart: () => {
          setRunAnimation(true)
        },
      })

      /*const lettersAnimation = gsap.to(pathRefs.current, {
      x: 0,
      duration: 1.5,
      ease: "power3.easeInOut",
      stagger: 0.24,
      delay: 2,
    })*/

      return () => {
        opacityAnimation.kill()
        //lettersAnimation.kill()
      }
    }, [])

    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="161"
        height="36"
        viewBox="0 0 161 36"
        fill="none"
        className={`w-${
          width ? width : "full"
        } h-auto fill-current transition-colors overflow-visible opacity-0 ${
          runAnimation ? "logo-animation" : ""
        } text-${baseColor ? baseColor : "negative"} ${className}`}
      >
        <path
          ref={el => pathRefs.current.push(el)}
          d="M13.1336 4.84638V0.692627C5.87983 0.692627 0 6.58168 0 13.8464V22.1543C0 29.419 5.87983 35.3085 13.1336 35.3085V31.1547C8.17847 31.1547 4.14767 27.1172 4.14767 22.1547V13.8464C4.14724 8.88392 8.17847 4.84638 13.1336 4.84638Z"
        />
        <path
          ref={el => pathRefs.current.push(el)}
          d="M33.1792 4.13289C40.8137 4.13289 47.025 10.3539 47.025 18.0004C47.025 25.6469 40.8137 31.8675 33.1792 31.8675C25.5447 31.8675 19.3334 25.6469 19.3334 18C19.3334 10.3531 25.5447 4.13289 33.1792 4.13289ZM33.1792 0C23.2537 0 15.2074 8.05893 15.2074 18C15.2074 27.9411 23.2537 36 33.1792 36C43.1047 36 51.151 27.9411 51.151 18C51.151 8.05893 43.1047 0 33.1792 0Z"
        />
        <path
          ref={el => pathRefs.current.push(el)}
          d="M62.4939 0.692627L72.9974 31.1543H73.5953L84.0499 0.692627H91.2679V35.3085H87.0948V4.84638H86.8071L76.2832 35.3081H70.2598L59.7861 4.84638H59.4461V35.3081H55.3243V0.692627H62.4939Z"
        />

        <path
          ref={el => pathRefs.current.push(el)}
          d="M113.362 36C123.287 36 131.334 27.9411 131.334 18C131.334 8.05887 123.287 0 113.362 0C103.436 0 95.3899 8.05887 95.3899 18C95.3899 27.9411 103.436 36 113.362 36Z"
          className={`fill-current text-${
            circleColor ? circleColor : "gray-400"
          }`}
        />
        <path
          ref={el => pathRefs.current.push(el)}
          d="M142.461 0.692627L155.814 31.1543H156.371V0.692627H160.366V35.3085H153.362L140.075 4.8468H139.629V35.3085H135.482V0.692627H142.461Z"
        />
      </svg>
    )
  }
)

AnimatedLogo.displayName = "AnimatedLogo"

export default AnimatedLogo
