import React, { useEffect } from "react"
import gsap from "gsap"

const ScrollDown = () => {
  useEffect(() => {
    gsap.to("#scroll-down", {
      scrollTrigger: {
        trigger: "#scroll-down",
        start: "bottom +90%",
        ease: "power2",
        toggleActions: "play reset play reset",
      },
      duration: 0.3,
      autoAlpha: 0,
      y: 100,
    })
  })

  return (
    <div
      id="scroll-down"
      className="text-14 text-grey-dark flex items-center justify-center"
    >
      <div className="scroll-mouse">
        <div className="scroll-mouse-point"></div>
      </div>
      <div className="mx-4 font-base text-14">SCROLL DOWN</div>
    </div>
  )
}

export default ScrollDown
