import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import MultilineText from "./MultilineText"
import { gsap } from "gsap/all"
import PropTypes from "prop-types"

const renderTranslation = translation => {
  const [t] = useTranslation()

  return (
    <MultilineText
      lines={t(`${translation}`, {
        returnObjects: true,
      })}
    />
  )
}

const renderText = text => {
  const lines = text.split("\\n")
  return lines.length > 1
    ? lines.map((line, index) => (
        <>
          {line}
          {index + 1 < lines.length ? <br /> : null}
        </>
      ))
    : text
}

const SectionDescription = ({
  text,
  translation,
  color,
  className,
  size,
  animated,
  animationOptions,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: animationOptions?.trigger
        ? {
            trigger: animationOptions?.trigger,
            start: animationOptions.top || "top center",
          }
        : undefined,
      y: "100px",
      opacity: 0,
      duration: 1,
      ease: "power3.easeOut",
      delay: animationOptions?.delay || 1.2,
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <p
      ref={ref}
      className={`${size ? size : "text-18"} font-base text-${
        color ? color : "dark"
      } font-light ${className ? className : ""}`}
    >
      {translation ? renderTranslation(translation) : renderText(text)}
    </p>
  )
}

SectionDescription.propTypes = {
  text: PropTypes.string,
  translation: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  animated: PropTypes.bool,
  animationOptions: PropTypes.shape({
    trigger: PropTypes.string,
  }),
}

export default SectionDescription
