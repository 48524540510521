import { gsap } from "gsap/all"
import React, { useEffect, forwardRef, useRef } from "react"

const PillImage = ({ src, className, animated, animationOptions }) => {
  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: animationOptions.trigger,
        start: animationOptions.top || "top center",
      },
      y: 150,
      opacity: 0,
      delay:
        animationOptions.delay || 1.6 + (animationOptions.staggerDelay || 0),
      duration: 1.5,
      ease: "power3.easeInOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div
      ref={ref}
      className={`mx-auto pill-image rounded-full ${
        src ? "" : "bg-black"
      } mt-12 shadow-2xl ${className ? className : ""}`}
    >
      {src ? (
        <div
          className="w-full h-full rounded-full bg-center bg-no-repeat bg-cover bg-top"
          style={{ backgroundImage: `url(${src})` }}
        />
      ) : null}
    </div>
  )
}

const CircleImage = ({ src, className, animated, animationOptions }) => {
  const ref = useRef()

  useEffect(() => {
    if (!animated) return

    const animation = gsap.from(ref.current, {
      scrollTrigger: {
        trigger: animationOptions.trigger,
        start: animationOptions.top || "top center",
      },
      y: 150,
      opacity: 0,
      delay:
        animationOptions.delay || 1.6 + (animationOptions.staggerDelay || 0),
      duration: 1.5,
      ease: "power3.easeInOut",
    })

    return () => {
      animation.kill()
    }
  }, [animated])

  return (
    <div
      ref={ref}
      className={`mx-auto circle-image rounded-full ${
        src ? "" : "bg-black"
      } mt-8 shadow-2xl ${className ? className : ""}`}
    >
      {src ? (
        <div
          className="w-full h-full rounded-full bg-center bg-no-repeat bg-cover bg-top"
          style={{ backgroundImage: `url(${src})` }}
        />
      ) : null}
    </div>
  )
}

const SectionImage = ({ type, src, className, animated, animationOptions }) => {
  switch (type) {
    case "pill":
      return (
        <PillImage
          src={src}
          className={className}
          animated={animated}
          animationOptions={animationOptions}
        />
      )

    case "circle":
      return (
        <CircleImage
          src={src}
          className={className}
          animated={animated}
          animationOptions={animationOptions}
        />
      )

    default:
      return null
  }
}

CircleImage.displayName = "CircleImage"
SectionImage.displayName = "SectionImage"

export default SectionImage
