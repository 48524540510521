import React from "react"

import Section from "@components/Section"
import SectionTitle from "@components/SectionTitle"
import SectionIntro from "@components/SectionIntro"
import SectionLine from "@components/SectionLine"
import SectionDescription from "@components/SectionDescription"
import Icon from "@components/icon"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const ContactButton = ({ href, icon, text, border }) => (
  <a
    href={href}
    className={`group w-full px-8 py-5 flex items-center justify-center relative transform transition-all ${
      border
        ? "border-2 md:border-4 border-white border-opacity-10 rounded-full hover:bg-white"
        : ""
    }`}
  >
    <div className="absolute left-8">
      <Icon
        type={icon}
        size="8"
        color="white"
        className={`${border ? "group-hover:text-black" : ""} md:w-12 md:h-12`}
      />
    </div>
    <h3
      className={`ml-4 text-white ${
        border ? "group-hover:text-black" : ""
      } text-18 font-base uppercase`}
    >
      {text}
    </h3>
  </a>
)

const Contacts = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section id="contact" background="negative" container>
      <SectionTitle
        translation="contacts.section.title"
        color="white"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#contact" }}
      />

      <SectionIntro
        color="white"
        className="mt-12"
        translation="contacts.section.intro"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#contact" }}
      />

      <div className="grid grid-cols-4 md:grid-cols-12 gap-8 mt-12">
        <div className="col-span-full md:col-span-5 col-start-2 md:col-start-6">
          <SectionLine
            background="white"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#contact" }}
          />
          <SectionDescription
            className="w-full mt-6 md:mt-12"
            color="white"
            translation="contacts.section.description"
            animated={breakpoints.lg}
            animationOptions={{ trigger: "#contact" }}
          />
        </div>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-12 gap-x-8 gap-y-4 mt-20 md:mt-56">
        <div className="col-span-full md:col-span-4 order-last md:order-none md:col-start-9">
          <ContactButton
            href="tel:+351218988160"
            text="+351 218 988 160"
            icon="phone"
          />
        </div>
        {/*<div className="col-span-full md:col-span-4">
          <ContactButton
            href="mailto:geral@comon.pt"
            text="Email"
            icon="email"
            border
          />
        </div> */}
      </div>
    </Section>
  )
}

export default Contacts
