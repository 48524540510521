const data = [
  {
    i18n: "transformation.business.section",
    name: "Business",
    projects: [
      {
        id: "project1",
        image: {
          desktop: require("../images/portfolio/desktop/FIDELIDADE.jpg")
            .default,
          mobile: require("../images/portfolio/mobile/FIDELIDADE.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/FIDELIDADE.jpg")
            .default,
        },
        theme: {
          main: "#EE2428",
          title: "#000000",
          title_secondary: "#ffffff",
          text: "#EE2428",
          details_title: "#EE2428",
          details_text: "#ffffff",
          background: "#000000",
        },
      },
      {
        id: "project2",
        image: {
          desktop: require("../images/portfolio/desktop/SEPHORA.jpg").default,
          mobile: require("../images/portfolio/mobile/SEPHORA.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/SEPHORA.jpg").default,
        },
        theme: {
          main: "#000000",
          title: "#000000",
          text: "#000000",
          details_title: "#000000",
          details_text: "#000000",
          background: "#ffffff",
        },
      },
    ],
  },
  {
    i18n: "transformation.digital.section",
    name: "Digital",
    projects: [
      {
        id: "project1",
        image: {
          desktop: require("../images/portfolio/desktop/VISTORIAS.jpg").default,
          mobile: require("../images/portfolio/mobile/VISTORIAS.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/VISTORIAS.jpg")
            .default,
        },
        theme: {
          main: "#0163A2",
          title: "#ffffff",
          title_secondary: "#000000",
          text: "#ffffff",
          text_secondary: "#0163A2",
          text_separator: "#EC1940",
          details_title: "#0163A2",
          details_text: "#000000",
          background: "#ffffff",
        },
      },
      {
        id: "project2",
        image: {
          desktop: require("../images/portfolio/desktop/SOLINCA.jpg").default,
          mobile: require("../images/portfolio/mobile/SOLINCA.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/SOLINCA.jpg").default,
        },
        theme: {
          main: "#D6605A",
          title: "#ffffff",
          text: "#D6605A",
          details_title: "#D6605A",
          details_text: "#ffffff",
          background: "#000000",
        },
      },
    ],
  },
  {
    i18n: "transformation.brand.section",
    name: "Brand",
    projects: [
      {
        id: "project1",
        image: {
          desktop: require("../images/portfolio/desktop/6AMCLUB.jpg").default,
          mobile: require("../images/portfolio/mobile/6AMCLUB.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/6AMCLUB.jpg").default,
        },
        theme: {
          main: "#C6FB02",
          title: "#FFFFFF",
          text: "#C6FB02",
          details_title: "#C6FB02",
          details_text: "#FFFFFF",
          background: "#000000",
        },
      },
      {
        id: "project2",
        image: {
          desktop: require("../images/portfolio/desktop/MCDONALDS.jpg").default,
          mobile: require("../images/portfolio/mobile/MCDONALDS.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/MCDONALDS.jpg")
            .default,
        },
        theme: {
          main: "#FFC423",
          title: "#FFFFFF",
          text: "#FFC423",
          details_title: "#FFC423",
          details_text: "#ffffff",
          background: "#392F29",
        },
      },
    ],
  },
  {
    i18n: "transformation.culture.section",
    name: "Culture",
    projects: [
      {
        id: "project1",
        image: {
          desktop: require("../images/portfolio/desktop/NOLABELS.jpg").default,
          mobile: require("../images/portfolio/mobile/NOLABELS.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/NOLABELS.jpg").default,
        },
        theme: {
          main: "#D6605A",
          title: "#000000",
          title_secondary: "#FFFFFF",
          text: "#D6605A",
          details_title: "#D6605A",
          details_text: "#ffffff",
          background: "#403C35",
        },
      },
      {
        id: "project2",
        image: {
          desktop: require("../images/portfolio/desktop/BESTCHAIR.jpg").default,
          mobile: require("../images/portfolio/mobile/BESTCHAIR.jpg").default,
          thumbnail: require("../images/portfolio/thumbs/BESTCHAIR.jpg")
            .default,
        },
        theme: {
          main: "#00B0FF",
          title: "#FFFFFF",
          title_secondary: "#FFFFFF",
          text: "#00B0FF",
          text_secondary: "#00B0FF",
          details_title: "#00B0FF",
          details_text: "#ffffff",
          background: "#000000",
        },
      },
    ],
  },
]

export { data }
