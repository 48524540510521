import React from "react"

import Section from "@components/Section"
import SectionIntro from "@components/SectionIntro"
import SectionTitle from "@components/SectionTitle"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const Marquee = () => {
  const breakpoints = useBreakpoint()

  return (
    <Section
      id="marquee"
      background="positive"
      className="pb-10 md:pb-72"
      container
    >
      <SectionTitle
        translation="marquee.section.title"
        animated={breakpoints.lg}
        animationOptions={{ trigger: "#marquee" }}
      />

      <div className="w-full mt-20">
        <SectionIntro
          size="80"
          text="We design human-first experiences that"
          translation="marquee.section.intro"
          animated={breakpoints.lg}
          animationOptions={{ trigger: "#marquee" }}
        />
      </div>
    </Section>
  )
}

export default Marquee
