import React from "react"

import Section from "@components/Section"
import Header from "@components/Header"

import Transformation from "./transformation"

import { data } from "../../data/transformations"
import { useTranslation } from "react-i18next"

const Portfolio = () => {
  const [t] = useTranslation()

  const _t = (key, value) => t(`${key}.${value}`)

  return (
    <Section
      id="portfolio"
      gradient={{ from: "blue-darker", to: "negative" }}
      className="pt-16 pb-24"
      container
    >
      {data.map(({ name, i18n, projects }) => (
        <Transformation
          key={name}
          name={name}
          intro={`${i18n}.intro`}
          description={`${i18n}.description`}
          projects={projects.map(project => ({
            ...project,
            i18n,
            name: _t(i18n, `projects.${project.id}.project`),
            client: _t(i18n, `projects.${project.id}.client`),
          }))}
        />
      ))}
    </Section>
  )
}

export default Portfolio
